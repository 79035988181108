import { createContext, useContext, useEffect, useState } from 'react';

export const globalResourcesContext = createContext<GlobalResourcesContext>(
  {} as any,
);

export type GlobalResourcesContext = {
  setResources: (value: Partial<Resources>) => void;
  resources: {
    productCard: {
      deliveryTime: string;
      configureButton: string;
      sampleButton: string;
    };
    search: {
      removeFiltersButton: string;
      sortLabel: string;
      filtersLabel: string;
      noResultsFound: string;
      searchResultsShowAllButton: string;
      searchResultsNoResultsButton: string;
      searchInputPlaceholder: string;
      searchNoResultsMessage: string;
      searchResultsTitle: string;
      readMoreButton: string;
      filters: { [key: string]: string };
      sorting: { [key: string]: string };
    };
  };
};

export type Resources = GlobalResourcesContext['resources'];

type GlobalResourcesProviderProps = {
  children: any;
  resources: Resources;
};

type ResourceSection = 'search' | 'productCard';

type ResourcesValue<T> = T extends 'search'
  ? Resources['search']
  : T extends 'productCard'
  ? Resources['productCard']
  : never;

export function useGlobalResources<T extends ResourceSection>(
  key: T,
): {
  resources: ResourcesValue<T> | null;
  setResources: (value: Partial<Resources>) => void;
} {
  const globalResources = useContext(globalResourcesContext);
  if (!globalResources?.resources) {
    return {
      resources: null,
      setResources: () => {},
    };
  }

  return {
    resources: globalResources.resources[key] as ResourcesValue<T>,
    setResources: globalResources.setResources,
  };
}

export function useTranslate<T extends ResourceSection>(section: T) {
  const { resources } = useGlobalResources(section);

  const translate = (key: keyof ResourcesValue<T>, mapKey?: string): string => {
    const value = resources?.[key];

    if (typeof value === 'string' && value) {
      return value;
    }

    if (typeof value === 'object' && value) {
      if (mapKey) {
        return (value as any)[mapKey] as string;
      }

      return value?.toString();
    }

    return `${section}_${key?.toString()}`;
  };

  return { translate };
}

const GlobalResourcesProvider: React.FC<GlobalResourcesProviderProps> = ({
  children,
  resources,
}) => {
  const [context, setContext] = useState<GlobalResourcesContext>({
    resources,
    setResources: () => {},
  });

  useEffect(() => {
    const setResources = (value: Partial<Resources>) => {
      const newResources = { ...context.resources, ...value };
      setContext({ ...context, resources: newResources });
    };

    setContext({ ...context, setResources });
  }, []);

  return (
    <globalResourcesContext.Provider value={context}>
      {children}
    </globalResourcesContext.Provider>
  );
};

export default GlobalResourcesProvider;
